<template>
  <title>Betaling | Dividenduitkering</title>

  <div class="container-fluid cmtm cpxs">
    <div class="row">
      <div class="col-12 du-column-white d-flex flex-column justify-content-center" :class="{
        'min-height cpm': !windowCheckerData.isMobileScreen,
        'cpxm': windowCheckerData.isMobileScreen,
        'min-height-mobile-s': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs': windowCheckerData.isMobileScreenXS
      }">
        <div class="du-title-text du-between-title mt-0 d-flex justify-content-center text-center" v-if="orderStatus.status_name">
          <h1>Betaling {{orderStatus?.status_name}}</h1>
        </div>
        <div class="du-between-subtext-v2  d-flex justify-content-center">
          <p>{{orderStatus?.paragraph}}</p>
        </div>
        <div class="du-between-button mt-0 d-flex justify-content-center">
          <a v-for="(btn, index) in orderStatus?.btns" :href="btn?.btn_href">
            <div class="du-question-control-right d-inline-block me-3">
              <button :class="btn?.btn_classList + ' du-button'">
                {{btn?.btn_name}}
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
import {useTest} from "@/components/utils/crud-test";
import {useAuth0} from "@auth0/auth0-vue";
import emitter from "@/components/eventBus";
import {onMounted, watchEffect} from "vue";
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "Payment",
  components: {WindowChecker},
  // setup(){
    // const { createTest } = useTest();
    // return{
    //   createTest,
    // }
  // },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
    async orderStatusChecker(status, id) {
      const user = localStorage.getItem("user")
      const price = localStorage.getItem("price")
      let url;
      if(user && price){
        url = `${API_BASE_URL}/mollie/index.php?price=${price}&user_id=${JSON.parse(user).data.userId}`;
      }
      switch (status) {
        case "succes":
          if(user){
            const storage = localStorage.getItem('discount')
            if(storage){
              const discount = JSON.parse(storage);
              discount.cod_status = 0;
              const requestOptions = {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(discount),
              };
              const response = await fetch(API_BASE_URL + "/discounts/index.php", requestOptions);
              const json = await response.json();
              if (json.success === true && json.data !== false) {
                localStorage.removeItem('discount')
              }
            }
            this.orderStatus.status_name = "succes"
            this.orderStatus.paragraph = "Uw betaling was succesvol. U kunt van start gaan met uw aanvraag. Uw aanvraag blijft 60 dagen opgeslagen, zodat u het zo goed mogelijk in kan vullen."
            this.orderStatus.btns =
                [
                  {
                    btn_classList: "du-button-dark",
                    btn_name: "GA NAAR OVERZICHT",
                    btn_href: `/overzicht`
                  }
                ]
          }
          else{
            this.$router.push(`/error/404`);
          }
          break;
        case "verlopen":
          if(id){
            this.$router.push(`/error/404`);
          }
          else{
            this.orderStatus.status_name = "verlopen"
            this.orderStatus.paragraph = "Uw betaling was verlopen. Probeer het opnieuw."
            this.orderStatus.btns =
                [
                  {
                    btn_classList: "du-button-dark",
                    btn_name: "OPNIEUW BETALEN",
                    btn_href: url
                  }
                ]
          }
          break;
        case "annuleert":
          if(id){
            this.$router.push(`/error/404`);
          }
          else{
            this.orderStatus.status_name = "annuleert"
            this.orderStatus.paragraph = "Uw betaling was annuleert. Probeer het opnieuw of ga terug naar het overzicht."
            this.orderStatus.btns =
                [{
                  btn_classList: "du-button-dark",
                  btn_name: "OPNIEUW BETALEN",
                  btn_href: url
                }, {
                  btn_classList: "",
                  btn_name: "TERUG OVERZICHT",
                  btn_href: `/overzicht`
                }]
          }
          break;
        case "mislukt":
          if(id){
            this.$router.push(`/error/404`);
          }
          else{
            this.orderStatus.status_name = "mislukt"
            this.orderStatus.paragraph = "Uw betaling was mislukt. Probeer het opnieuw."
            this.orderStatus.btns =
                [
                  {
                    btn_classList: "du-button-dark",
                    btn_name: "OPNIEUW BETALEN",
                    btn_href: url
                  }
                ]
          }
          break;
        default:
          this.$router.push(`/error/404`);
      }
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Maanden beginnen bij 0
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
  },
  created() {
    this.orderStatus = {
      status_name: "",
      paragraph: "",
      btns:[]
    }
    const payStatus = this.$route.params.paymentStatus
    this.orderStatusChecker(payStatus)
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      mollieLink: API_BASE_URL + "/mollie/index.php",
      orderStatus:{
        status_name: "",
        paragraph: "",
        btns:[]
      },
    }
  },
}
</script>

<style scoped>

</style>
