<template>
  <div class="container-fluid">  <!--class="wrapper"-->
    <div class="row">
      <Header></Header>
    </div>

    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <div :key="route.name" class="row" :class="{'cpbm': windowCheckerData.isMobileScreen}">
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view>
  </div>
  <Footer></Footer>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>


<script>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WindowChecker from "@/components/WindowChecker.vue";
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {

  name: `HowItWorks`,
  components: {WindowChecker, Header, Footer},

  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    };
  },

  watch: {
    '$route': 'currentRoute'
  },

  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name
        if((this.$route.name) === "VerifyCodeView") {
          document.body.style.backgroundColor = "white";
          const mainLogo = document.getElementById("headerLogo")
          if(mainLogo) {
            mainLogo.src = require("@/assets/Logo_Zwart.png")
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_kleur.png")
          }
        }
        else if (this.$route.name === "QuestionList") {
          document.body.style.backgroundColor = "#FFFFFF";
          const navLinks = document.getElementsByClassName("nav-link")
          if(navLinks.length > 0){
            Array.from(navLinks)?.forEach(el => {
              el.style.color = '#006C75'
            });
          }
          const mainLogo = document.getElementById("headerLogo")
          if(mainLogo) {
            mainLogo.src = require("@/assets/Logo_Zwart.png")
          }
          // const footer = document.querySelector("footer")
          // footer.style.color = 'black'
          const pCopy = document.getElementById("copy")
          if(pCopy){
            pCopy.style.color = "#006C75"
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_kleur.png")
          }
        }
        else if((this.$route.name) === "RegisterView"  ) {
          document.body.style.backgroundColor = "#e1ebf5";
          const navLinks = document.getElementsByClassName("nav-link")
         if(navLinks.length > 0) {
           Array.from(navLinks)?.forEach(el => {
             el.style.color = '#006C75'
           });
         }
          const mainLogo = document.getElementById("headerLogo")
          if(mainLogo) {
            mainLogo.src = require("@/assets/Logo_Zwart.png")
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_kleur.png")
          }

          const urls = document.getElementsByClassName("drv-footer-link")
          if(urls.length > 0){
            urls?.forEach(el => {
              el.style.color = "#032B28"
            });
          }

          const copyEl = document.getElementById("copy")
          copyEl.style.color = "#032B28"
        }
        // else if((this.$route.name) === "Welcome") {
        //   document.body.style.backgroundColor = "#006C75";
        // }
        else {
          document.body.style.backgroundColor = '#006C75';
          const navLinks = document.getElementsByClassName("nav-link")
          if(navLinks.length > 0){
            Array.from(navLinks)?.forEach(el => {
              el.style.color = 'white'
            });
          }
          const headerLogo = document.getElementById("headerLogo")
          if(headerLogo) {
            headerLogo.src = require("@/assets/Logo_Wit.png")
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_wit.png")
          }
          const pCopy = document.getElementById("copy")
          if(pCopy) {
            pCopy.style.color = "white"
          }
        }
      });
    }
  },
  async beforeMount() {
    const obj = {
      check_older_tests: true
    }
    const requestOptions = {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(obj),
    };
    const response = await fetch(API_BASE_URL + "/tests/index.php", requestOptions);
    const json = await response.json();
  }
};

</script>


<style>
@import "../src/assets/main.css";
</style>
