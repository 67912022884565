<template>
  <title>Aanvraag starten | Dividenduitkering</title>
  <div class="col-12 d-md-none cmtm">
    <div class="du-lottie-player">
      <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
    </div>
  </div>
  <div class="col-12 cptm">
    <h2 class="largeText mb-3" >Door middel van een serie aan vragen achterhalen wij de mogelijkheid en omvang van uw recht tot het uitkeren van dividend uit uw onderneming.</h2>
  </div>
  <div class="col-md-6 col-12 container-content" :class="{
    'font-white': isDarkPage
  }">
      <div class="cmbm">
        <h3>Start uw aanvraag</h3>
        <p>Toetsduur: 20 – 60 minuten</p>
        <p>Een en ander zal afhankelijk zijn in hoeverre de toets het nodig acht actuele cijfers en/ of prognoses/ begrotingen te gebruiken.</p>
      </div>

      <div class="cmbm">
        <p><u>Privacy gegarandeerd</u></p>
        <p>Na afronding van de toets ontvangt u per mail een pdf-bestand. De gegevens worden bij afronding direct verwijderd. Totdat u de toets afrondt, zullen de ingevoerde gegevens maximaal 60 dagen bewaard blijven.</p>
      </div>

      <div class="cmbm">
        <p><u>Start aanvraag</u></p>
        <p>Bent u een nieuwe gebruiker van Dividenduitkering.nl? In de volgende stap kunt u zich registreren voor onze website. Bestaande gebruikers kunnen direct inloggen.
          Na aanmelding kunt u een nieuwe toets starten of een bestaande toets voortzetten. Al uw toetsen worden overzichtelijk weergegeven.
        </p>
      </div>

      <div class="du-question-controls du-controls-content d-flex">
        <div class="w-100">

          <div class="du-question-control-left d-md-inline-block d-block cmbs">
            <router-link to="/overzicht" class="w-100 d-block">
              <button class="du-button">START AANVRAAG
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>
            </router-link>
          </div>

        </div>
      </div>
  </div>

  <div class="col-md-6 d-md-flex d-none du-lottie-column mb-3">
    <div class="du-lottie-player h-100">
      <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>


</template>

<script>

import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WindowChecker from "@/components/WindowChecker.vue";
import {useTheme} from "@/components/utils/theme";


export default {
  name: `StartRequest`,
  components: {WindowChecker, Navigation, Header, Footer},
  setup(){
    const { isDarkPage } = useTheme()
    return {
      isDarkPage,
    }
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
};
</script>

<style scoped>

</style>
