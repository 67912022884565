<template>
  <header>
    <div class="du-header">
      <div class="du-header-title">
        <router-link to="/">
          <!-- style="height: 9vh;"<h3 class="d-inline-block headerTitle" ><span>DIVIDEND</span>UITKERING.NL</h3> -->
          <img v-if="!isDarkPage" id="headerLogo" class="headerLogo" src="@/assets/Logo_Zwart.png" alt="Dividend uitkering logo" srcset="">
          <img v-if="isDarkPage" id="headerLogo" class="headerLogo" src="@/assets/Logo_Wit.png" alt="Dividend uitkering logo" srcset="">
        </router-link>
<!--        <p v-if="isMenuOpen">{{isMenuOpen}}</p>-->
      </div>
<!--      <p> Count is {{ store.count }}</p>-->
<!--      <p> Count is {{ store.test }}</p>-->
<!--      <button @click="store.increment(1)">Increment</button>-->
<!--      <button @click="store.waitAndAdd()">Wait and add</button>-->

      <button class="navbar-toggler d-inline-block d-xl-none" :class="{'dark-icon': !isDarkPage}"
              type="button"
              @click="isMenuOpen = !isMenuOpen">
        <span v-if="!isMenuOpen">
            <i class="fas fa-bars" ></i>
        </span>
        <span v-if="isMenuOpen">
            <i class="fas fa-xmark"></i>
        </span>
      </button>
    </div>
    <navigation :active-page="routeName" @toggleMenu="receiveEmit" :toggleCollapseMenu="isMenuOpen"></navigation>
  </header>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import {useTheme} from "@/components/utils/theme";
import { ref } from 'vue';
export default{
  components: {Navigation},
  setup(){
    const { isDarkPage } = useTheme()
    return{
      isDarkPage
    }
  },
  data(){
    return {
      routeName: null,
      menuStatus: "fas fa-bars",
      isMenuOpen: false
    }
  },
  watch: {
    '$route': 'currentRoute'
  },
  methods: {
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name
      });
    },
    receiveEmit() {
      this.isMenuOpen = false;
    }

  }
}
</script>
