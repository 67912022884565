<template>

  <nav class="navbar navbar-expand-xl">

    <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{'show': isMenuOpen}">
      <div class="du-navbar">
        <ul class="nav navbar-nav" :class="isDarkPage ? 'dark-theme' : 'light-theme'">

          <li class="nav-item">
            <router-link class="nav-link" tag="a" to="/" @click="closeMenu()">WELKOM</router-link>
            <span v-bind:class = "(activePage === 'Welcome')?'d-block' : ''" class="active-dot"></span>
          </li>

          <li class="nav-item postion-relative">
            <router-link class="nav-link" tag="a" to="/hoe-werkt-het" @click="closeMenu()" aria-expanded="false">HOE WERKT HET</router-link>
            <span v-bind:class = "(activePage === 'HowItWorks')?'d-block' : ''" class="active-dot"></span>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" tag="a" to="/start-aanvraag" @click="closeMenu()">START UW AANVRAAG</router-link>
            <span v-bind:class = "(activePage === 'StartRequest' || activePage === 'New-Request')?'d-block' : ''" class="active-dot"></span>
          </li>

          <li v-if="isAuthenticated" class="nav-item mb-2 mb-md-0 parent-nav-item">
            <router-link class="nav-link" tag="a" to="/overzicht" @click="closeMenu()">OVERZICHT TOETSEN</router-link>
            <span v-bind:class = "(activePage === 'RequestView' || activePage === 'DiscountOverview')?'d-block' : ''" class="active-dot"></span>
            <div v-if="isAdmin" class="nav-item mb-2 mb-md-0 child-nav-item">
              <router-link class="nav-link" tag="a" to="/kortingscodes" @click="closeMenu()">KORTINGSCODES</router-link>
            </div>
          </li>

          <li class="nav-item auth-buttons">
            <Auto0></Auto0>
          </li>

<!--          <p v-if="activePage === 'QuestionList'" class="nav-item du-navbar-usertext"><span>|</span> WELKOM 'NAAM' <i class="fa-solid fa-chevron-down du-navbar-usertext-icon"></i></p>-->
<!--          <p v-if="isLoggedIn && user !== null" class="nav-item du-navbar-usertext"><span>|</span> Welkom {{ user.firstName }} <i class="fa-solid fa-chevron-down du-navbar-usertext-icon"></i></p>-->

      </ul>
      </div>
    </div>
  </nav>

</template>


<script>
import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'
import Auto0 from "@/components/auth0.vue";
import { useAuth0 } from '@auth0/auth0-vue';
import { useTheme } from "@/components/utils/theme";
import {checkPermissionByEmail, checkAdminByEmail} from "@/components/utils/check-permission-by-email";
import { Collapse } from 'bootstrap';
let userStore
let userVar
let loggedIn
export default {
  name: 'Navigation',
  components: {Auto0},

  setup() {
    const { isAuthenticated } = useAuth0();
    const { isDarkPage } = useTheme()
    const { isUserExclusive } = checkPermissionByEmail();
    const { isAdmin } = checkAdminByEmail();
    // const { emit } = defineEmits(['toggleMenu']);
    userStore = useUserStore()
    const {user, isLoggedIn} = storeToRefs(userStore)
    userVar = user.value
    loggedIn = isLoggedIn.value
    return {
      isDarkPage,
      user,
      isLoggedIn,
      isAuthenticated,
      isUserExclusive,
      isAdmin,
    }
  },

  data() {
    return {
      fillCount: 0,
      isMenuOpen: false
    };
  },

  props: {
    activePage: {
      type: String
    },
    toggleCollapseMenu:{
      type: Boolean
    }
  },

  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    closeMenu(){
      console.log('Hello closeMenu')
      this.$emit('toggleMenu');
      const navbar = document.getElementById('navbarSupportedContent');
      if (navbar.classList.contains('show')) {
        console.log('collapse!')
        const bsCollapse = new Collapse(navbar, { toggle: false });
        bsCollapse.hide();
      }
      else{
        console.log('Nope')
      }
    }
  },

  watch: {
    user: {
      // This will let Vue know to look inside the array
      deep: true,
    },
    toggleCollapseMenu(newValue) {
      this.isMenuOpen = newValue
    }
  },

}



</script>
<style>
.navbar-nav{
  align-items: center;
}

.nav-item{
  position: relative;
}
.active-dot{
  background-color:#A1FFE1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 14px;
  right: 0;
  position: absolute;
  display: none;

  &.d-block{
    display: block!important;
  }
}


</style>
