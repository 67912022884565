<script>
import { useAuth0 } from '@auth0/auth0-vue';
import {onMounted, watchEffect} from "vue";
import emitter from "@/components/eventBus";
import {checkPermissionByEmail} from "@/components/utils/check-permission-by-email";
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

async function gettingUser(auth0) {
  const obj = {
    email: auth0.user.value.email,
    email_verified: auth0.user.value.email_verified,
    name: auth0.user.value.name,
    nickname: auth0.user.value.nickname,
    picture: auth0.user.value.picture,
    sub: auth0.user.value.sub,
    updated_at: auth0.user.value.updated_at
  }
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(obj),
  };
  const response = await fetch(API_BASE_URL + "/users/index.php", requestOptions);
  return await response.json();
}

emitter.on('gettingUser', gettingUser);

export default{
  setup() {
    const auth0 = useAuth0();
    const { isUserExclusive } = checkPermissionByEmail();
    watchEffect(async () => {
      if (auth0.isAuthenticated.value) {
        if (auth0.user) {
          const json = await gettingUser(auth0)
          if(json.data){
            localStorage.setItem("user", JSON.stringify(json))
          }
        }
      }
    })
    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      login() {
        auth0.loginWithRedirect();
      },
      logout() {
        localStorage.removeItem("user");
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      },
      showUser(){
      },
      capitalize(text) {
        if (!text) return '';
        return text.charAt(0)?.toUpperCase() + text.slice(1)?.toLowerCase();
      },
    }

  },
  methods:{

  },
  mounted() {

  }
}
</script>

<template>
    <span v-if="!isAuthenticated && !isLoading"  class="nav-link" id="qsLoginBtn" @click.prevent="login">INLOGGEN</span>
    <div class="nav-item dropdown " v-if="isAuthenticated">
      <div
          class="nav-link dropdown-toggle text-uppercase"
          id="profileDropDown"
          data-toggle="dropdown"
      >
<!--        <span>{{ user.given_name ? user.given_name : user.nickname }}</span>-->
        <!-- <button v-on:click="showUser()" >showusr</button> -->
        <img  v-if="user.picture" class="userPicture"  :src="user.picture">
        <img  v-else class="userPicture"  :src="'@assets/profile-silhouette.webp'" >
      </div>
      <div class="dropdown-menu dropdown-menu-right navDropdown">
        <div class="userInfo">
          <div class="top">
<!--            <b>{{ user.given_name ? user.given_name?.toUpperCase() : user.nickname?.toUpperCase() }}</b>-->
            <img class="userPicture" :src=user.picture >
          </div>

          <div class="body">
            <b v-if="userID">{{userID}}</b>
            <b>
              {{ user.given_name ? capitalize(user.given_name) : capitalize(user.nickname) }}{{ user.given_name || user.nickname ? ' ' : '' }}{{ user.family_name || '' }}
            </b>
            <br/>
            {{  (user.given_name && user.family_name) ? `${user.given_name.replace(" ","")}.${user.family_name.replace(" ","")}@mooredrv.com` : user.email }}
          </div>
        </div>

<!--        <router-link to="/profile" class="dropdown-item dropdown-profile" @click="showUser">-->
<!--          <font-awesome-icon class="mr-3" icon="user" />Mijn profiel-->
<!--        </router-link>-->
        <!-- <router-link to="/overzicht" class="dropdown-item dropdown-profile" @click="showUser">
          <font-awesome-icon class="mr-3" icon="user" />Overzicht
        </router-link> -->
        <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
          <font-awesome-icon class="mr-3" icon="power-off" />Uitloggen
        </a>
      </div>
    </div>
</template>

<style scoped>
  .dropdown-toggle,
  .userInfo{
    *{
      color: #00212E;
    }
  }

  .dropdown-item{
    background-color: white;
    color:#00212E;
  }
</style>
